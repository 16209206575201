import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import { StaticQuery, graphql } from "gatsby"
import throttle from "lodash/throttle"

class Nav extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mobileHidden: true,
      isScrolled: false,
    }

    this.throttledScroll = throttle(this.checkScroll, 150)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.throttledScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledScroll)
  }

  toggleMobileMenu = e => {
    this.setState({
      mobileHidden: !this.state.mobileHidden,
    })
  }

  checkScroll = e => {
    if (window.scrollY > 0) {
      this.setState({
        isScrolled: true,
      })
    } else {
      this.setState({
        isScrolled: false,
      })
    }
  }

  render() {
    return (
      <nav
        id="header"
        className={classnames(
          "fixed",
          "w-full",
          "z-30",
          "top-0",
          { "text-white": !this.state.isScrolled },
          { "bg-white": this.state.isScrolled }
        )}
      >
        <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
          <div className="pl-4 flex items-center">
            <Link
              className={classnames(
                "toggleColour",
                "no-underline",
                "hover:no-underline",
                "font-bold",
                "text-2xl",
                "lg:text-4xl",
                { "text-white": !this.state.isScrolled },
                { "text-okto-dark": this.state.isScrolled }
              )}
              to="/"
            >
              {this.props.name}
            </Link>
          </div>

          <div className="block lg:hidden pr-4">
            <button
              onClick={this.toggleMobileMenu}
              id="nav-toggle"
              className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-800 hover:border-teal-500 appearance-none focus:outline-none"
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>

          <div
            className={classnames(
              "w-full",
              "flex-grow",
              "lg:flex",
              "lg:items-center",
              "lg:w-auto",
              "lg:block",
              "mt-2",
              "lg:mt-0",
              "bg-white",
              "lg:bg-transparent",
              "text-black",
              "p-4",
              "lg:p-0",
              "z-20",
              "lg:pb-0",
              "pb-12",
              { hidden: this.state.mobileHidden }
            )}
            id="nav-content"
          >
            <ul className="list-reset lg:flex justify-end flex-1 items-center lg:mb-0 mb-12">
              <li className="mr-3" >
                {this.props.faqPath && <Link to={this.props.faqPath} className={classnames("insline-block", "py-2", "px-4", 'text-black', "no-underline", { "font-bold": this.props.active && this.props.active === "faq", 'text-white': !this.state.isScrolled })}>FAQ</Link>}
              </li>
            </ul>
            <Link
              to="/"
              className={classnames(
                "mx-auto",
                "lg:mx-0",
                "hover:underline",
                "bg-white",
                "text-gray-800",
                "font-bold",
                "rounded-full",
                "mt-4",
                "lg:mt-0",
                "py-4",
                "px-8",
                "shadow",
                "opacity-75",
                "no-underline",
                "hover:no-underline",
                { gradient: !this.state.mobileHidden || this.state.isScrolled },
                {
                  "text-white":
                    !this.state.mobileHidden || this.state.isScrolled,
                },
                {
                  "text-okto-dark":
                    this.state.mobileHidden && !this.state.isScrolled,
                }
              )}
            >
              Formations
            </Link>
          </div>
        </div>

        <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
      </nav>
    )
  }
}

const NavHandler = ({ active }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
          allMarkdownRemark (
            filter: { frontmatter: { type: { eq: "question"}, isRoot: { eq: true } } }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                    }
                }
            }
        }
        }
      `}
      render={data => (
        <Nav active={active} name={data.site.siteMetadata.title} faqPath={data.allMarkdownRemark.edges.length > 0 ? data.allMarkdownRemark.edges[0].node.frontmatter.path : undefined} />
      )}
    />
  )
}

export default NavHandler
